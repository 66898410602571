import axios from "@node/axios";
import VueCookies from "@node/vue-cookies";
import { Notification } from "@node/element-ui";
import i18n from "@o-din/src/i18n.js";

let auth_token = VueCookies.get("auth_token");

let adapter = axios.create({
  baseURL: "/",
  headers: {
    "Accept": "application/json",
    "Content-type": "application/json",
    "Authorization": `Token ${auth_token}`
  }
})

let errorInterceptor = function (error) {
  const statusCode = error.response ? error.response.status : undefined;
  console.log(`-> not_stonks: ${statusCode}`);
  if (!statusCode) {
    Notification.error({ title: i18n.t('errors.net_err'), message: i18n.t('errors.net_err_message') });
  } else {
    switch (statusCode) {
      case 401:
        VueCookies.remove("current_user");
        VueCookies.remove("features");
        localStorage.removeItem('menu')
        let link = document.createElement('a');
        link.href = `${window.location.origin}/sign_out`;
        link.setAttribute('data-method', 'delete');
        document.body.appendChild(link);
        link.click();
        break;
      case 403:
        console.log("meh");
        break;
      case 404:
        window.location = '/pageNotFound';
        break;
      case 422:
        console.log("meh");
        break;
      case 500:
        Notification.error({ title: i18n.t('errors.server_error'), message: i18n.t('errors.server_err_message') });
        break;
      case 502:
        Notification.warning({ title: i18n.t('errors.server_err_res'), message: i18n.t('errors.net_err_message') });
        break;
      default:
        Notification.error({ title: i18n.t('errors.unknown_err'), message: error });
        break;

    }
  }

  return Promise.reject(error.response.data.errors);
}

adapter.interceptors.response.use((r) => r, errorInterceptor);

export default adapter;
