import deepmerge from "@node/deepmerge";
import Vue from "@node/vue/dist/vue.js";
import VueI18n from "@node/vue-i18n";

const user = JSON.parse(localStorage.getItem('current_user')) || { locale: 'en' }

Vue.use(VueI18n);

  let messages = {};

  const modules = import.meta.glob("@o-din/../../config/locales/**/*.yml", { eager: true })

  for (const path in modules) {
    messages = deepmerge(messages, modules[path].default);
  }

export default new VueI18n({
  locale: user.locale, messages
})
